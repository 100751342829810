import React from 'react'
import I18n from '../../../locales/I18n'
import {
  MdOutlineGroups,
  MdOutlineCollectionsBookmark,
  MdDirectionsRun,
  MdOutlineFactCheck,
  MdMilitaryTech,
  MdOutlinePsychology
} from 'react-icons/md'
import { PieChart } from '@domoscio/domoscio-ui'

interface Data {
  [key: string]: any | number | undefined
}

type absoluteNumbersCibling = 'learners' | 'knowledge_nodes' | 'challenges'

/*
 * List of data props of AbsoluteNumber in ProgressionScreenLock
 */

export const absoluteNumbersElements = (
  data: Data | undefined,
  cibling: absoluteNumbersCibling = 'learners',
  isAllTheme?: boolean
) => {
  const container = { containerClassName: 'small_container' }
  if (cibling === 'learners') {
    return {
      ...container,
      icon: <MdOutlineGroups />,
      data: data?.nbr_students || 0,
      title: I18n.t('progress.learners', { count: data?.nbr_students || 0 }),
      generalTooltipText: I18n.t('engagement.tooltips.learners')
    }
  } else if (cibling === 'knowledge_nodes') {
    return {
      ...container,
      icon: <MdOutlineCollectionsBookmark />,
      data: data?.nbr_knowledge_nodes || 0,
      title: I18n.t('lock.skill', { count: data?.nbr_knowledge_nodes || 0 }),
      generalTooltipText: I18n.t(`progress.tooltips.skill_${isAllTheme ? 'general' : 'theme'}`)
    }
  } else {
    return {
      ...container,
      icon: <MdDirectionsRun />,
      data: data?.nbr_actions_accepted || 0,
      title: I18n.t('progress.accepted_challenges', {
        count: data?.nbr_actions_accepted || 0
      }),
      generalTooltipText: I18n.t('progress.tooltips.accepted_challenges')
    }
  }
}

/*
 * List of data props of Percentage in ProgressionScreenLock
 */
export const percentageElements = (data: Data | undefined) => {
  const container = { containerClassName: 'medium_container' }
  return {
    ...container,
    progress: data?.perc_correct_answers || 0,
    isZero: data?.perc_correct_answers === 0,
    title: I18n.t('progress.correct_answers'),
    generalTooltipText: I18n.t('progress.tooltips.correct_answers'),
    icon: <MdOutlineFactCheck />
  }
}

type pieChartCibling = 'questions' | 'challenges' | 'challenges2'

/*
 * List of data props of Card.PieChart in ProgressionScreenLock
 */
export const pieChartElements = (
  data: Data | undefined,
  cibling: pieChartCibling = 'questions',
  primaryColor: string | undefined
) => {
  const container = { containerClassName: 'medium_container' }
  const colors = {
    primaryColor: primaryColor || '#00AACE',
    secondaryColor: '#103F4B'
  }
  if (cibling === 'questions') {
    return {
      ...container,
      generalTooltipText: I18n.t('progress.tooltips.review_repartition'),
      icon: <MdOutlinePsychology />,
      title: I18n.t('progress.review_repartition'),
      children: React.createElement(PieChart, {
        ...colors,
        pieWidth: 180,
        datas: [
          {
            name: I18n.t('progress.review_levels.days'),
            value: (data?.perc_memory_levels_distribution || {})?.few_days
          },
          {
            name: I18n.t('progress.review_levels.week'),
            value: (data?.perc_memory_levels_distribution || {})?.one_week
          },
          {
            name: I18n.t('progress.review_levels.weeks'),
            value: (data?.perc_memory_levels_distribution || {})?.several_weeks
          },
          {
            name: I18n.t('progress.review_levels.months'),
            value: (data?.perc_memory_levels_distribution || {})?.several_months
          }
        ]
      })
    }
  } else if (cibling === 'challenges') {
    return {
      generalTooltipText: I18n.t('progress.challenges.completion.tooltip'),
      icon: <MdMilitaryTech />,
      title: I18n.t('progress.challenges.completion.title'),
      children: React.createElement(PieChart, {
        ...colors,
        pieWidth: 170,
        datas: [
          {
            name: I18n.t('progress.challenges.completion.performed'),
            value: (data?.perc_actions_repartition || {})?.perc_actions_performed
          },
          {
            name: I18n.t('progress.challenges.completion.failed'),
            value: (data?.perc_actions_repartition || {})?.perc_actions_failed
          },
          {
            name: I18n.t('progress.challenges.completion.abandonned'),
            value: (data?.perc_actions_repartition || {})?.perc_actions_abandoned
          }
        ]
      })
    }
  } else {
    return {
      generalTooltipText: I18n.t('progress.tooltips.challenges_completion'),
      icon: <MdDirectionsRun />,
      title: I18n.t('progress.challenges.difficulty.title'),
      children: React.createElement(PieChart, {
        ...colors,
        pieWidth: 170,
        datas: [
          {
            name: I18n.t('progress.challenges.difficulty.easy'),
            value: (data?.perc_actions_distribution || {})?.done_easily
          },
          {
            name: I18n.t('progress.challenges.difficulty.hardly'),
            value: (data?.perc_actions_distribution || {})?.done_with_difficulty
          }
        ]
      })
    }
  }
}
