import React, { ReactNode, useContext } from 'react'
import ReactJoyride from 'react-joyride'
import { OnboardingTooltip } from '@domoscio/domoscio-ui'
import I18n from '../../locales/I18n'
import { User, Session } from '../../contexts/parameters'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { DataIsReadyContext } from '../../contexts/dataIsReadyContext'

type Props = {
  children: ReactNode
}

const steps = () => {
  return [
    {
      title: I18n.t('tour.step_1.title'),
      target: '.step1',
      content: I18n.t('tour.step_1.content'),
      disableBeacon: true,
      placement: 'bottom' as const
    },
    {
      title: I18n.t('tour.step_2.title'),
      target: '.step2',
      content: I18n.t('tour.step_2.content'),
      disableBeacon: true,
      placement: 'bottom' as const
    },
    {
      title: I18n.t('tour.step_3.title'),
      target: '.step3',
      content: I18n.t('tour.step_3.content'),
      disableBeacon: true,
      placement: 'bottom' as const
    },
    {
      title: I18n.t('tour.step_4.title'),
      target: '.information_icon_position_right',
      content: I18n.t('tour.step_4.content'),
      disableBeacon: true,
      placement: 'bottom' as const
    },
    {
      title: I18n.t('tour.step_5.title'),
      target: '.step5',
      content: I18n.t('tour.step_5.content'),
      disableBeacon: true,
      placement: 'bottom' as const
    }
  ]
}

const TourWrapper = ({ children }: Props) => {
  const { isDataReady } = useContext(DataIsReadyContext)

  const sessionDispatch = React.useContext(Session.Dispatch)

  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)
  const runTour = !(user?.onboarding?.analytics?.home === undefined
    ? false
    : user?.onboarding?.analytics?.home)

  const handleCallbackJoyRide = (data: any) => {
    const { action, status } = data

    // Send finished tour to server
    if (status === 'finished' || action === 'reset') {
      sessionDispatch({ isFirstConnection: true })
      userDispatch({
        onboarding: domUtils.updateTour(user.onboarding, 'analytics', { home: true })
      })
    }
  }

  return (
    <div className='tourWrapper'>
      <ReactJoyride
        floaterProps={{ disableAnimation: true }}
        // @ts-ignore:next-line
        tooltipComponent={OnboardingTooltip}
        steps={steps()}
        continuous={true}
        run={isDataReady && runTour}
        styles={{
          options: {
            zIndex: 10000
          },
          overlay: {
            left: '0px'
          },
          spotlight: {
            borderRadius: 80,
            padding: '0px'
          }
        }}
        callback={arg => handleCallbackJoyRide(arg)}
      />
      {children}
    </div>
  )
}

export default TourWrapper
