import { useQuery } from 'react-query'
import { getCacheBody } from '../utils/shared'
import { domoscioAccess } from '../api/domoscio_access'
import { useContext } from 'react'
import { Session } from '../contexts/parameters'
import { DataIsReadyContext } from '../contexts/dataIsReadyContext'
import { FilterBodyContext } from '../contexts/parameters/filterBodyContext'

interface Args {
  tab:
    | 'HubProgression'
    | 'LockProgression'
    | 'LockEngagement'
    | 'HubEngagement'
    | 'HubUsage'
    | 'LockUsage'
  onSuccess?: () => void
}

export const useFetchAnalyticsDatas = ({ tab }: Args) => {
  const session = useContext(Session.Dispatch)

  const sessionData = useContext(Session.State)

  const { setIsDataReady } = useContext(DataIsReadyContext)

  const bodyData = useContext(FilterBodyContext)

  const isEnabled =
    bodyData.learning_program_uid.length > 0 &&
    bodyData.student_group_uid.length > 0 &&
    bodyData.start_date !== ''

  const route = 'get' + tab
  const { data, status, isLoading, isSuccess } = useQuery(
    [tab, getCacheBody(bodyData)],
    async () => {
      const dataProgressionHub = await domoscioAccess[route](bodyData)
      return dataProgressionHub
    },
    {
      enabled: isEnabled,
      onSuccess: data => {
        if (data.statusCode === 200) {
          const experience = data?.body?.learning_programs[0]
          if (experience) {
            const isExportDisabled = experience?.exportDisable
            session({
              isExportDisabled: {
                ...(sessionData.isExportDisabled || {}),
                [experience?.uid]: isExportDisabled
              }
            })
          }
        }
        setIsDataReady(true)
      }
    }
  )

  return { data, status, isLoading, isSuccess }
}
