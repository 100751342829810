import I18n from '../../locales/I18n'
import { Option, Skill } from '../../types/filter'

export const knowledgeNodesLevel = (data: any, skills: Skill[], selectedTheme: Option) => {
  const knowledgeNodes =
    data?.objectives?.find(elem => elem?.uid === selectedTheme.uid)?.values?.knowledge_nodes || []

  return knowledgeNodes.map((kn: any) => {
    const target = skills.find(skill => skill?.uid === kn?.uid)
    return {
      label: target?.name || '',
      value: ((kn?.average_memory_level || 1) * 100) / 6 || 0
    }
  })
}

export const getLegendOfLevel = (level: number, scale: 'percentage' | 'cog' = 'percentage') => {
  let levels = [0, 2, 4, 6]

  if (scale === 'percentage') {
    levels = levels.map(lvl => (lvl * 100) / 6)
  }

  if (level < levels[1]) {
    return I18n.t('progress.some_days')
  } else if (level < levels[2]) {
    return I18n.t('progress.a_week')
  } else if (level < levels[3]) {
    return I18n.t('progress.some_weeks')
  } else {
    return I18n.t('progress.some_months')
  }
}
export function useCiblingDatas(data, body) {
  const hasContext =
    body?.objective && body?.objective.length > 0 && !['all', 'no'].includes(body.objective[0].id)

  if (hasContext) {
    // Return objective datas
    const objective = (data?.objectives || []).filter(obj => {
      return obj.uid === body.objective[0].uid
    })
    return { datas: objective.length > 0 ? objective[0].values : {} }
  } else {
    // Return experience datas
    const learningProgram = (data?.learning_programs || []).filter(lp => {
      return lp.uid === body.learning_program_uid[0]
    })
    return { datas: learningProgram.length > 0 ? learningProgram[0].values : {} }
  }
}
