/**
 * @file Manages the Reactjs Container and init methods.
 */

import './core/App.css'

import React from 'react'
import Navigation from './navigation/Navigation'
import Initializers from './core/Initializers'
import ClientHeightObserver from './core/ClientHeightObserver'
import ErrorWrapper from './components/ErrorBoundary/ErrorFallback'
import { Parameters } from './contexts/parameters'
import { ModalProvider } from './contexts/ModalProvider'
import { useLocation } from 'react-router-dom'

function App() {
  const location = useLocation()

  const isStudentScreen = location.pathname.includes('student')

  return (
    <ErrorWrapper>
      <ClientHeightObserver>
        <Parameters>
          <Initializers>
            <ModalProvider>
              <div className={`App domScroll ${isStudentScreen ? 'studentScreenSize' : ''}`}>
                <Navigation />
              </div>
            </ModalProvider>
          </Initializers>
        </Parameters>
      </ClientHeightObserver>
    </ErrorWrapper>
  )
}

export default App
