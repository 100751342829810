/* eslint-disable @typescript-eslint/no-use-before-define */
/**
 * @file Manages the methods of the HomeScreen screen.
 */

import * as React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { Loader } from '@domoscio/domoscio-ui'
import HomeNav from '../components/home/HomeNav'
import HomeNavSmall from '../components/home/HomeNavSmall'
import StatFilters from '../components/filters/StatFilters'
import StatFiltersSmall from '../components/filters/StatFiltersSmall'
import TourWrapper from '../components/Tour/TourWrapper'
import { DataIsReadyForTourWrapper } from '../contexts/dataIsReadyContext'

import { useDomToolkit, Style } from '../styles/dom-toolkit'
import '../styles/screens.scss'
import FeaturePopup from '../components/FeaturePopup'

/**
 * Renders the Home Screen
 *
 * @category Screens
 */
function HomeScreen() {
  // 1. Initialize classes & states
  const classes: any = useDomToolkit()
  const styles: any = getStyles(classes)
  const location = useLocation()
  const [filterLoaded, setFilterLoaded] = React.useState(false)

  // 2. Load filters
  const initFilters = (filters: any): void => {
    const ready = Object.values(filters)
      .map((filter: any) => filter.isReady) // catch isReady values
      .every(ready => ready === true) // check if all are true

    if (ready) {
      setFilterLoaded(true) // if filters are ready, set filterLoaded to true
    }
  }
  const isStudentScreen = location.pathname.split('/').includes('student')
  return (
    <>
      {/* Display <Loader/> until filters are loaded */}
      {!filterLoaded && <Loader />}

      {/* HomeScreen when filter are loaded display */}
      <div
        className={styles.HomeScreen.container(filterLoaded)}
        style={{ display: filterLoaded ? 'flex' : 'none' }}
      >
        <DataIsReadyForTourWrapper>
          <TourWrapper>
            <div className={isStudentScreen ? 'hidden' : ''}>
              <HomeNav />
              <StatFilters onInit={initFilters} />
              <StatFiltersSmall onInit={initFilters} />
            </div>
            <div className={`${classes.domMarginTop} ${classes.domFlex1}`}>
              <Outlet />
            </div>
            <HomeNavSmall />
          </TourWrapper>
          <FeaturePopup />
        </DataIsReadyForTourWrapper>
      </div>
    </>
  )
}

// 🎨 File style declaration
const getStyles = (classes: any) =>
  Style.create({
    HomeScreen: {
      container: (filterLoaded: boolean) =>
        Style.classes([
          'HomeScreen',
          classes.domHeight100,
          classes.domWidth100,
          classes.domFlexColumn,
          filterLoaded && 'fadeIn'
        ])
    }
  })

export default HomeScreen
