import React, { useContext } from 'react'
import '../lock.scss'
import { AbsoluteNumber, Percentage, StatLoader } from '@domoscio/domoscio-ui'
import TitleScreen from '../../../components/TitleScreen'
import Table from '../../../components/DataTable/Table'
import I18n from '../../../locales/I18n'
import { FilterBodyContext } from '../../../contexts/parameters/filterBodyContext'
import { ObjectivesFilterContext } from '../../../contexts/parameters/filterContext'
import { Theme } from '../../../contexts/parameters'
import { absoluteNumbersElements, percentageElements } from './datas'
import { useFetchAnalyticsDatas } from '../../../hooks/useFetchAnalyticsDatas'
import { useCiblingDatas } from '../utils'

function EngagementScreenLock() {
  const body = useContext(FilterBodyContext)
  const theme = React.useContext(Theme.State)
  const { selectedObjective, objectiveFilters } = useContext(ObjectivesFilterContext)
  const primaryColor = theme?.domBgPrimary

  const { data, status } = useFetchAnalyticsDatas({
    tab: 'LockEngagement'
  })
  const bodyWithContext = { ...body, objective: selectedObjective }
  const { datas } = useCiblingDatas(data?.body, bodyWithContext)

  const _emptyDiv = (n: number) => {
    return [...Array(n)].map((e, i) => <div key={i}></div>)
  }

  const isOnlyQuestion = objectiveFilters.objectivesOfSelectedExp.every(
    obj => obj.memorize_options.workflow === 'only_questions'
  )

  return status === 'success' ? (
    <div className='EngagementScreenLock'>
      <div className='mainEngagementLock'>
        <div className='step3'>
          <TitleScreen
            titleScreen={I18n.t('engagement.title_engagement')}
            data-testid='titleScreenEngagementHub'
          />
          <div className='graphes'>
            <div className='firstLine'>
              {absoluteNumbersElements(datas).map((element, i) => (
                <div className='componentAbsoluteNumber' key={i}>
                  <AbsoluteNumber {...element} />
                </div>
              ))}
            </div>
            <div className='secondLine'>
              {percentageElements({ ...datas, objective: selectedObjective }).map((element, i) => (
                <div className='percentage' key={i}>
                  <Percentage {...element} />
                </div>
              ))}
            </div>
          </div>
          {!isOnlyQuestion && (
            <>
              <TitleScreen titleScreen={I18n.t('global.challenges')} />
              <div className='graphes'>
                <div className='firstLine'>
                  {absoluteNumbersElements(datas, 'challenge').map((element, i) => (
                    <div className='componentAbsoluteNumber' key={i}>
                      <AbsoluteNumber {...element} />
                    </div>
                  ))}
                  {_emptyDiv(3)}
                </div>
                <div className='secondLine'>
                  {percentageElements(datas, 'challenge').map((element, i) => (
                    <div className='percentage' key={i}>
                      <Percentage {...element} />
                    </div>
                  ))}
                  {_emptyDiv(2)}
                </div>
              </div>{' '}
            </>
          )}
        </div>
        <Table page='LockEngagement' />
      </div>
    </div>
  ) : (
    <StatLoader primaryColor={primaryColor} />
  )
}

export default EngagementScreenLock
