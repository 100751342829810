import React from 'react'
import { MdArrowBack } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDomToolkit } from '../../../styles/dom-toolkit'
import { domoscioAccess } from '../../../api/domoscio_access'
import { Loader } from '@domoscio/domoscio-ui'
import I18n from '../../../locales/I18n'

import '../lock.scss'

/*
 * Main StudentScreen fonction
 */
function StudentScreen() {
  const classes: any = useDomToolkit()

  const navigate = useNavigate()
  const location = useLocation()
  const { uid } = location.state
  const title = location.state?.name || location.state?.uid

  const [chartUrl, setChartUrl] = React.useState(null)
  const [iframeLoading, setIframeLoading] = React.useState(true)

  // Get chart url method
  function loadMemorizeProgressionChart() {
    domoscioAccess
      .getMemorizeProgressionChart({
        student_uid: uid,
        locale: I18n.locale
      })
      .then(response => {
        setChartUrl(response.url)
      })
  }

  // Load chart onMount
  React.useEffect(() => {
    loadMemorizeProgressionChart()
  }, [])

  return (
    <div className='StudentScreen'>
      <div className={`${classes.domFlex} ${classes.domFlexMiddle}`}>
        <button
          className={`back-button ${classes.domBgPrimary} ${classes.domBorder0} ${classes.domCursorPointer}`}
          onClick={() => navigate(-1)}
        >
          <MdArrowBack size={24} color={'#fff'} />
        </button>
        <div className={`${classes.domMarginLeft} ${classes.domText20}`}>{title}</div>
      </div>
      {/* Chart container */}
      <main className={`${classes.domMarginLargeTop} ${classes.domHeight100}`}>
        {chartUrl ? (
          <>
            <iframe
              src={chartUrl}
              onLoad={() => setIframeLoading(false)}
              sandbox='allow-same-origin allow-scripts allow-popups allow-forms allow-downloads'
              height='800'
              width='100%'
              style={{
                display: iframeLoading ? 'none' : 'initial'
              }}
            />
            {iframeLoading && <Loader />}
          </>
        ) : (
          <Loader />
        )}
      </main>
    </div>
  )
}

export default StudentScreen
